// export enum language {
// 	deDE = "de-DE",
// 	frFR = "fr-FR",
// 	enUS = "en-US",
// }
export type Translation = {
	name: string
	email: string
	tradecertificate: string
	your_name: string
	your_email: string
	continue: string
	// your_buiness_license: string
	ppnonwoven: string
	ppwoven: string
	nylonpolyester: string
	cotton: string
	paper: string
	material: string
	type: string
	size: string
	printing_method: string
	grammage: string
	message: string

	regularshopingbag: string
	coolerbag: string
	silkscreen: string
	laminated: string
	laminatedC4: string
	backpack: string

	paper_bag: string
	standard_weight: string

	interested_cta: string
	contact_alt_cta: string

	select_file_for_upload: string
	file_x1_notaccepted: string
	file_too_big: string
	file_type_invalid: string

	select_material: string
	select_type: string
	select_size: string
	select_grammage: string
	select_printing_method: string
	and_click_continue: string

	add_tradecertificate: string
	add_message: string

	data_complete: string
	agree_tos: string
	waiting_for_grecaptcha: string
	grecaptcha_protected: string

	sent_thanks: string
	sent_success: string
}
type AllTranslation = {
	"de-DE": Translation
	"fr-FR": Translation
	"en-US": Translation
}

export const translations: AllTranslation = {
	"de-DE": {
		name: "Name",
		email: "E-Mail-Adresse",
		tradecertificate: "Gewerbebescheinigung",
		continue: "Weiter",

		your_name: "Ihr Name",
		your_email: "Ihre E-Mail-Adresse",
		// your_buiness_license: "Ihre Gewerbebescheinigung",

		ppnonwoven: "PP NON WOVEN",
		ppwoven: "PP WOVEN",
		nylonpolyester: "Nylon / Polyester",
		cotton: "Baumwolle",
		paper: "Papier",

		material: "Taschenmaterial",
		type: "Taschentyp",
		size: "Taschengröße",
		printing_method: "Druckverfahren",
		grammage: "Materialgewicht",
		message: "Nachricht",

		regularshopingbag: "Normale Einkaufstüte",
		coolerbag: "Kühltasche",

		silkscreen: "Siebdruck",
		laminated: "Laminiert",
		laminatedC4: "Laminiert 4 C",

		backpack: "Rucksack",
		paper_bag: "Papier Einkaufstüte (FSC Craft Paper)",

		standard_weight: "Standardgewicht",

		interested_cta:
			"Sie sind Interessiert? Schicken Sie uns eine unverbindliche Anfrage über unser Kontaktformular.",
		contact_alt_cta: `Sie können uns auch schreiben an <span class="inertlink email">unsere E-Mail-Adresse</span> oder uns anrufen unter <span class="inertlink phone">unserer Telefonnummer</span>`,

		select_file_for_upload: "Klicken, um eine Datei zum Upload auszuwählen",
		file_x1_notaccepted: '"$1" kann nicht akzeptiert werden.',
		file_too_big: "Die Datei ist zu groß.",
		file_type_invalid: "Der Dateityp wird nicht unterstützt.",

		select_material: "Bitte wählen Sie ein Taschenmaterial",
		select_type: "Bitte wählen Sie einen Taschentyp",
		select_size: "Bitte wählen Sie eine Taschengröße",
		select_grammage: "Bitte wählen Sie ein Materialgewicht",
		select_printing_method: "Bitte wählen Sie eine Drucktechnik",

		add_tradecertificate:
			"Bitte fügen Sie für Preisanfragen ihre Gewerbebescheinigung (Bild oder PDF) hinzu. Ohne diese ist uns eine Bearbeitung Ihrer Anfrage leider nicht möglich.",
		add_message: "Hier ist noch Platz für weitere Details Ihrer Anfrage",

		and_click_continue: "und klicken Sie auf Weiter",

		data_complete:
			"Ihre Angaben sind vollständig und können nun an uns abgeschickt werden.",
		agree_tos:
			'Ich erkläre mich mit der Verarbeitung meiner angegebenen Daten zum Zweck einer Rückantwort einverstanden. Weitere Hinweise finden sich in unserer <a target="_blank" href="/privacy">Datenschutzerklärung</a>.',
		waiting_for_grecaptcha: "Warte auf Google Recaptcha ...",

		sent_thanks: "Vielen Dank!",
		sent_success:
			"Ihre Anfrage ist erfolgreich an uns übermittelt worden und wir melden uns in Kürze bei Ihnen.",

		grecaptcha_protected: `Diese Seite wird durch reCAPTCHA geschützt und es gelten die Google-<a target="_blank" href="https://policies.google.com/privacy">Datenschutzerklärungen</a> und <a target="_blank" href="https://policies.google.com/terms">-Nutzungsbedingungen</a>.`,
	},
	"fr-FR": {
		name: "Nom",
		email: "Adresse e-mail",
		tradecertificate: "certificat de commerce",
		continue: "Suivant",

		your_name: "Votre nom",
		your_email: "Votre adresse e-mail",
		// your_buiness_license: "Ihre Gewerbebescheinigung",

		ppnonwoven: "PP NON WOVEN",
		ppwoven: "PP WOVEN",
		nylonpolyester: "Nylon / Polyester",
		cotton: "Coton",
		paper: "Papier",

		material: "Matériau du sac",
		type: "Type de sac",
		size: "Format de poche",
		printing_method: "Technologie d'impression",
		grammage: "Grammage",
		message: "Message",

		regularshopingbag: "Sac standard ",
		coolerbag: "Sac isotherme",

		silkscreen: "Sérigraphie",
		laminated: "Feuilleté",
		laminatedC4: "Feuilleté 4 C",

		backpack: "Sac à dos",
		paper_bag: "Sac de transport jetables en papier FSC",
		standard_weight: "poids standard",

		interested_cta: "Vous êtes intéressé? Alors appelez-nous ou écrivez-nous.",

		contact_alt_cta: `Vous pouvez nous écrire à <span class="inertlink email">notre e-mail</span> ou téléphonez au <span class="inertlink phone">notre téléphone</span>`,

		select_file_for_upload: "Joindre un fichier (cliquez ici)",
		file_x1_notaccepted: '"$1" ne peut être accepté.',
		file_too_big: "Le fichier est trop volumineux.",
		file_type_invalid: "Le type de fichier n'est pas pris en charge.",

		select_material: "Choisissez votre matériau s'il vous plaît",
		select_type: "Choisissez votre type de sac s'il vous plaît",
		select_size: "Choisissez votre format de poche s'il vous plaît",
		select_grammage: "Choisissez votre grammage s'il vous plaît",
		select_printing_method:
			"Choisissez votre technologie d'impression s'il vous plaît",
		and_click_continue: "et cliquez suivant",

		add_tradecertificate:
			"Veuillez ajouter votre certificat de commerce pour les demandes de prix (Téléchargement image ou PDF). Sans cela, il ne nous est malheureusement pas possible de traiter votre demande.",
		add_message:
			"Il reste encore de la place pour plus de détails sur votre demande.",
		data_complete:
			"Vos données sont complètes et vous pourrez envoyer la demande maintenant.",
		agree_tos: `En soumettant votre demande, vous acceptez le traitement de vos données aux fins d'une réponse. Pour plus d'informations, s'il vous plaît voir notre <a target="_blank" href="/privacy">Politique de confidentialité</a>.`,
		waiting_for_grecaptcha: "Attendez Google Recaptcha s'il vous plaît ...",

		sent_thanks: "Merci beaucoup!",
		sent_success:
			"Nous avons reçu votre message et nous prendrons contact avec vous sous peu.",

		grecaptcha_protected: `Ce site est protégé par reCAPTCHA. En conséquence, la <a target="_blank" href="https://policies.google.com/privacy">Politique de confidentialité</a> et <a target="_blank" href="https://policies.google.com/terms">les conditions de service</a> de Google devront être pris en compte.`,
	},
	"en-US": {
		name: "Name",
		email: "Email",
		tradecertificate: "trade certificate",
		continue: "Continue",

		your_name: "Your Name",
		your_email: "Your email adress",
		// your_buiness_license: "Ihre Gewerbebescheinigung",

		ppnonwoven: "PP NON WOVEN",
		ppwoven: "PP WOVEN",
		nylonpolyester: "Nylon / Polyester",
		cotton: "Cotton",
		paper: "Paper",

		material: "Bag material",
		type: "Bag type",
		size: "Bag size",
		printing_method: "Printing method",
		grammage: "Grammage",
		message: "Message",

		regularshopingbag: "Regular shopping bag",
		coolerbag: "Cooler bag",

		silkscreen: "Silkscreen",
		laminated: "Laminated",
		laminatedC4: "Laminated 4 C",

		backpack: "Backpack",
		paper_bag: "Paper Shopping Bag (FSC Craft Paper)",
		standard_weight: "standard weight",

		interested_cta:
			"You are interested? Then write us by using our contact form.",

		contact_alt_cta: `You can also write us at <span class="inertlink email">our email address</span> or call us on <span class="inertlink phone">our phone number</span>`,

		select_file_for_upload: "Click to select a file for upload",
		file_x1_notaccepted: 'The file "$1" is too large.',
		file_too_big: "The file is too large.",
		file_type_invalid: "The file type is not supported.",

		select_material: "Please select a material",
		select_type: "Please select a type",
		select_size: "Please select a size",
		select_grammage: "Please select a grammage",
		select_printing_method: "Please select a printing method",
		and_click_continue: "and click continue",

		add_tradecertificate:
			"Please add your trade certificate for price inquiries (image or PDF). Without this, it is unfortunately not possible for us to process your request.",
		add_message: "Please feel free to add a message to your request.",
		data_complete: "Your data is complete and can now be submitted.",
		agree_tos:
			'By submitting my request, I agree to the processing of my data for the purpose of a response. Further information can be found in our <a target="_blank" href="/privacy">privacy policy</a>',
		waiting_for_grecaptcha: "Waiting for Google Recaptcha ...",

		sent_thanks: "Thank you!",
		sent_success:
			"Your request has been sent successfully and we will contact you shortly.",

		grecaptcha_protected: `This site is protected by reCAPTCHA and the Google <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.`,
	},
}
