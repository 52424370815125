import * as React from "react"
import * as ReactDOM from "react-dom"

import Wizard from "./Wizard"
import { translations } from "./translations"
const currentScriptTag = document.currentScript


const wpcf7Form = currentScriptTag.parentElement
const wpcf7Host = wpcf7Form.parentElement
const mountDiv = document.createElement("div")
wpcf7Host.appendChild(mountDiv)

const language = document
	.querySelector(`input#translation-placeholder`)
	?.getAttribute("value")
const translation = translations[language] || translations['en-US']

const props = { translation, language }
ReactDOM.render(<Wizard {...props} />, mountDiv)

