export default {
	ppnonwoven: {
		regularshopingbag: {
			"32 x 28 + 10": {
				"80 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
				"100 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
			},

			"32 x 28 + 20": {
				"80 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
				"100 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
			},
			"38 x 42 + 10": {
				"80 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
				"100 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
			},
			"38 x 42 + 20": {
				"80 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
				"100 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
			},
			"42 x 32 + 20 ": {
				"80 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
				"100 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
			},
		},
		coolerbag: {
			"38 x 42 + 15": {
				"80 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
				"100 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
			},
			"42 x 32 + 20": {
				"80 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
				"100 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
			},
			"42 x 42 + 15 ": {
				"80 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
				"100 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
			},
			"42 x 50 + 20": {
				"80 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
				"100 gsm": { silkscreen: 1, laminated: 1, laminatedC4: 1, offset: 1 },
			},
		},
	},
	ppwoven: {
		regularshopingbag: {
			"44x44 +15": {
				"140 gsm": { laminated: 1, laminatedC4: 1 },
			},
		},
	},
	nylonpolyester: {
		regularshopingbag: {
			"34 x 39 + 13,5": {
				"190T": { "4 C": 1, Sublimation: 1 },
			},
			"34 x 58 + 12": {
				"190T": { "4 C": 1, Sublimation: 1 },
			},
			"52 x 36 + 20": {
				"190T": { "4 C": 1, Sublimation: 1 },
			},
		},
		coolerbag: {
			"32 x 22 + 20": {
				"600D": { silkscreen: 1, "4 C": 1 },
			},
			"35 x 35 + 20": {
				"600D": { silkscreen: 1, "4 C": 1 },
			},
			"40 x 35 + 20": {
				"600D": { silkscreen: 1, "4 C": 1 },
			},
		},
		backpack: {
			"35 x 42": {
				"210D": { silkscreen: 1, "4 C": 1, Sublimation: 1 },
			},
			"38 x 42": {
				"210D": { silkscreen: 1, "4 C": 1, Sublimation: 1 },
			},
		},
	},
	cotton: {
		regularshopingbag: {
			"42 x 36 + 20": {
				"140 gsm": { Silkscreen: 1 },
			},
			"38 x 42 + 10": {
				"140 gsm": { Silkscreen: 1 },
			},
			"38 x 42": {
				"140 gsm": { Silkscreen: 1 },
			},
		},
	},
	paper: {
		"paper_bag": {
			"32x44 +17": {
				"90 gsm": { Silkscreen: 1 },
			},
			"32x27 +17": {
				"90 gsm": { Silkscreen: 1 },
			},
		},
	},
}
