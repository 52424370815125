import React, { useState, useRef, useEffect } from "react"
import ReactDOM from "react-dom"
import "./Wizard.css"

import tree from "./tree"
import { Translation } from "./translations"
import InertAnchor from "./InertAnchor"

const ATTESTATION_LIMIT = 1054560
const ATTESTATION_TYPES_REGEX = /(^image\/|^application\/pdf$)/

// const nameByDepth = []
const nameByDepth = ["material", "type", "size", "grammage", "printing_method"]
const stepsTotal = [
	"start",
	"tradecertificate",
	...nameByDepth,
	"message",
	"submit",
	"sent",
]
const stepsBackable = stepsTotal.slice(2, -1)
const stepsContable = stepsTotal.slice(1, -2)

type InquiryData = {
	name?: string
	email?: string
	language?: string

	material?: string //"ppnonwoven" | "ppwoven" | "nylonpolyester" | "cotton" | "paper"
	type?: string
	size?: string
	grammage?: string
	printing_method?: string

	message?: string
	tradecertificate?: File

	agreed: boolean
}

enum Status {
	editing,
	sending,
	sent,
}

type WizardProps = {
	// wpcf7: {
	// 	_wpcf7: string
	// 	_wpcf7_locale: string
	// 	_wpcf7_version: string
	// 	_wpcf7_unit_tag: string
	// 	_wpcf7_container_post: string
	// 	_wpcf7_posted_data_hash: string
	// }
	translation: Translation
	language: string
	// wpcf7id: string
	// phoneDisplay: string
	// phoneClean?: string
	// email: string
}

const fileSizeSI = (a: number) => {
	const d: number = 1e3
	const e: number = (Math.log(a) / Math.log(d)) | 0
	const num = (a / Math.pow(d, e)).toFixed(1)
	const ext = e ? "kMGTPEZY"[e - 1] + "B" : "Bytes"
	return `${num} ${ext}`
}
const Wizard = ({ translation, language }: WizardProps) => {
	// const [Currentstep, setCurrentstep] = useState<string>("start")
	// const [Path, setPath] = useState<Array<string>>([])
	// const [Selection, setSelection] = useState<string>("")
	const [Index, setIndex] = useState<number>(0)
	const [Data, setData] = useState<InquiryData>({ agreed: false, language })
	const [State, setState] = useState<Status>(Status.editing)
	const attestationInput = useRef<HTMLInputElement>()

	// const loc: language = "de"
	const _ = (key: string, params?: Array<string>) => {
		const src = key in translation ? translation[key] : key
		if (!params) return src
		else return params.map((p, i) => src.replace(`$${i + 1}`, p))
	}
	// const _ = (key: string) => translations[loc][key] || key
	const stepName = stepsTotal[Index]

	const tradecertificateSizeValid =
		Data.tradecertificate && Data.tradecertificate.size <= ATTESTATION_LIMIT
	const tradecertificateTypeValid =
		Data.tradecertificate &&
		Data.tradecertificate.type.match(ATTESTATION_TYPES_REGEX)
	const validations = {
		start: Data.name && Data.email && Data.email.match(/^[^@]+@[^@]+$/),
		tradecertificate:
			Data.tradecertificate &&
			tradecertificateSizeValid &&
			tradecertificateTypeValid,
		message: true,
		submit: Data.agreed,
	}
	let canAdvance =
		stepName in validations ? validations[stepName] : Data[stepName]

	const advance = (evt?: React.MouseEvent) => {
		if (evt) evt.preventDefault()
		if (canAdvance) setIndex((n) => n + 1)
		// if (cantAdvance) return
		// if (isTreestep) {
		// 	setPath((p) => p.concat(Selection))
		// 	setSelection("")
		// }
		// setCurrentstep((step) => stepsTotal[stepsTotal.indexOf(step) + 1])
	}
	const retreat = (evt: React.MouseEvent) => {
		evt.preventDefault()
		if (Index <= 0) return

		setIndex((n) => n - 1)
		setData((d) => {
			return { ...d, agreed: false }
		})

		// const selection = Path[Path.length - 1]
		// setSelection(selection)
		// setPath(Path.slice(0, -1))
	}
	const submit = async (evt: React.MouseEvent) => {
		evt.preventDefault()
		if (State === Status.sending) return

		setState(Status.sending)
		const formData = new FormData()
		for (const name in Data) {
			formData.append(name, Data[name])
		}
		// leave these empty
		formData.append('website', "")
		formData.append('title', "")

		formData.append(
			"details",
			"Details:\n" +
				nameByDepth.map((name) => `  ${name}: ${Data[name]}`).join("\n")
		)
		const senderFields = ["name", "email", "language", "agreed"]
		formData.append(
			"sender",
			"Sender:\n" +
				senderFields.map((name) => `  ${name}: ${Data[name]}`).join("\n")
		)

		const req: RequestInit = {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			body: formData,
		}
		const wpcf7id = document
			.querySelector(`input[name="_wpcf7"]`)
			?.getAttribute("value")
		const url = `/wp-json/contact-form-7/v1/contact-forms/${wpcf7id}/feedback`
		const response = await fetch(url, req)
		// console.log(`submitted`, formData, response)
		setState(Status.sent)
		setIndex((n) => n + 1)
	}

	const getAvailableOptions = (key) => {
		const data = nameByDepth
			.slice(0, nameByDepth.indexOf(key))
			.reduce((acc, k) => acc && Data[k] && acc[Data[k]], { ...tree })
		return data ? Object.keys(data) : []
	}

	const stepClass = (stepName) => {
		// return `wz-step-${stepName}`
		const stepIndex = stepsTotal.indexOf(stepName)
		return `wz-step-${
			Index === stepIndex ? "active" : stepIndex < Index ? "left" : "right"
		} wz-step-${stepName}`
	}
	const update = (key, val?) => {
		return (evt?: React.MouseEvent) => {
			if (evt) evt.preventDefault()
			// if (!evt || !evt.target || typeof evt.target.value === "undefined") return
			const value = val || (evt && evt.target && evt.target.value)
			setData((d) => {
				if(nameByDepth.indexOf(key) < 0)
					return { ...d, [key]: value }
				return nameByDepth
					.slice(nameByDepth.indexOf(key)+1)
					.reduce((acc, k) => { delete acc[k]; return acc }, { ...d, [key]: value })
			})
		}
	}
	const selectAttestation = (evt: any) => {
		const selectedFile =
			evt && evt.target && evt.target.files && evt.target.files[0]

		if (selectedFile) {
			console.log(`setting tradecertificate file`, selectedFile)
			setData((d) => {
				return { ...d, tradecertificate: selectedFile }
			})
		} else if (evt.preventDefault) {
			evt.preventDefault()
			if (!attestationInput || !attestationInput.current) return
			attestationInput.current.click()
		}
	}

	return (
		<div className="Wizard">
			<div
				className={`wz-steps wz-select-n-${Index} wz-select-s-${stepsTotal[Index]}`}
			>
				<div className={`wz-step ${stepClass("start")}`}>
					<div className="wz-2col-wrapper">
						<div className="wz-2col-bigger">
							<div className="wz-upper">
								<input
									aria-label={_("your_name")}
									placeholder={_("your_name")}
									type="text"
									onChange={update("name")}
									className="input-box"
								/>
								<br />
								<input
									aria-label={_("your_email")}
									placeholder={_("your_email")}
									type="email"
									onChange={update("email")}
									className="input-box"
								/>
							</div>
							<div className="wz-lower">
								<div className="wz-buttons">
									<button
										className={`wz-button wz-button-primary wz-button-nextstep`}
										disabled={!canAdvance}
										onClick={advance}
									>
										{_("continue")}
									</button>
								</div>
							</div>
						</div>
						<div className="wz-2col-smaller">
							<p>{_("interested_cta")}</p>
							<p>
								<strong
									dangerouslySetInnerHTML={{ __html: _("contact_alt_cta") }}
								/>
							</p>
						</div>
					</div>
				</div>
				{/* <pre>{JSON.stringify(dataPoint, null, '  ')}</pre> */}
				{nameByDepth.map((optionName, optionIndex) => {
					// const dataPoint: any = getData(tree, Path.slice(0, optionIndex))
					// const dataKeys = Object.keys(dataPoint)
					// console.log(`dataKeys`, dataKeys)
					const options = getAvailableOptions(optionName)
					return (
						<div
							key={optionName}
							className={`wz-step ${stepClass(optionName)}`}
						>
							<div className="wz-upper">
								{options.map((dataKey) => (
									<button
										key={dataKey}
										className={`wz-button wz-data-button ${
											Data[optionName] === dataKey ? "wz-button-selected" : ""
										}`}
										onClick={update(optionName, dataKey)}
									>
										{_(dataKey)}
									</button>
								))}
							</div>
							<div className="wz-lower">
								<p>
									<strong>
										{_(`select_${optionName}`)} {_("and_click_continue")}
									</strong>
								</p>
								<div className="wz-buttons">
									<button
										className="wz-button wz-button-prevstep"
										onClick={retreat}
									>
										<i className="fa-chevron-left fas" aria-hidden="true"></i>
									</button>
									<button
										className={`wz-button wz-button-primary wz-button-nextstep`}
										disabled={!canAdvance}
										onClick={advance}
									>
										{_("continue")}
									</button>
								</div>
							</div>
						</div>
					)
				})}
				<div className={`wz-step ${stepClass("tradecertificate")}`}>
					<div className="wz-upper">
						<button
							onClick={selectAttestation}
							className={`wz-button wz-data-button ${
								!Data.tradecertificate
									? ""
									: validations.tradecertificate
									? "wz-button-selected"
									: "wz-button-error"
							}`}
						>
							{!Data.tradecertificate || !validations.tradecertificate
								? _("select_file_for_upload")
								: `${Data.tradecertificate.name} (${fileSizeSI(
										Data.tradecertificate.size
								  )})`}
						</button>
						{Data.tradecertificate && !validations.tradecertificate && (
							<p className="wz-error-message">
								<strong>
									{_("file_x1_notaccepted", [Data.tradecertificate.name])}
								</strong>
								<br />
								{!tradecertificateSizeValid && (
									<>
										<span>{_("file_too_big")}</span>
										<br />
									</>
								)}
								{!tradecertificateTypeValid && (
									<>
										<span>{_("file_type_invalid")}</span>
									</>
								)}
							</p>
						)}

						<input
							ref={attestationInput}
							type="file"
							style={{ display: "none" }}
							onChange={selectAttestation}
							// onChange={updateFile("tradecertificate")}
						/>
					</div>
					<div className="wz-lower">
						<p>
							<strong>{_("add_tradecertificate")}</strong>
						</p>
						<div className="wz-buttons">
							<button
								className="wz-button wz-button-prevstep"
								onClick={retreat}
							>
								<i className="fa-chevron-left fas" aria-hidden="true"></i>
							</button>
							<button
								className={`wz-button wz-button-primary wz-button-nextstep`}
								disabled={!canAdvance}
								onClick={advance}
							>
								{_("continue")}
							</button>
						</div>
					</div>
				</div>
				<div className={`wz-step ${stepClass("message")}`}>
					<div className="wz-upper">
						<textarea onChange={update("message")} />
					</div>

					<div className="wz-lower">
						<p>
							<strong>{_("add_message")}</strong>
						</p>
						<div className="wz-buttons">
							<button
								className="wz-button wz-button-prevstep"
								onClick={retreat}
							>
								<i className="fa-chevron-left fas" aria-hidden="true"></i>
							</button>
							<button
								className={`wz-button wz-button-primary wz-button-nextstep`}
								disabled={!canAdvance}
								onClick={advance}
							>
								{_("continue")}
							</button>
						</div>
					</div>
				</div>
				<div
					className={`wz-step ${stepClass("submit")} ${
						State === Status.sending ? "wz-step-locked" : ""
					}`}
				>
					<div className="wz-upper">
						<p>{_("data_complete")}</p>
						{/* <table>
							<tbody>
								{Object.keys(Data).map((key) => {
									const hiddenKeys = [
										"agreed",
										"language",
										"tradecertificate",
										"message",
									]
									return (
										<tr
											key={key}
											className={`${
												hiddenKeys.indexOf(key) >= 0 ? "row-hidden" : ""
											}`}
										>
											<th>{_(key)}</th>
											<td>
												{key === "tradecertificate"
													? Data[key].name
													: _(Data[key])}
											</td>
										</tr>
									)
								})}
							</tbody>
						</table> */}
					</div>

					<div className="wz-lower">
						<label className="wz-tos-message">
							<input
								type="checkbox"
								checked={Data.agreed}
								disabled={State === Status.sending}
								onChange={() => {
									setData((d) => {
										return { ...d, agreed: !d.agreed }
									})
								}}
							/>
							<span dangerouslySetInnerHTML={{ __html: _("agree_tos") }} />
						</label>
						<div className="wz-buttons">
							<button
								className="wz-button wz-button-prevstep"
								onClick={retreat}
							>
								<i className="fa-chevron-left fas" aria-hidden="true"></i>
							</button>
							<button
								className={`wz-button wz-button-primary`}
								disabled={!canAdvance || State === Status.sending}
								onClick={submit}
							>
								Abschicken
							</button>
						</div>
					</div>
				</div>
				<div className={`wz-step ${stepClass("sent")}`}>
					<div className="wz-upper">
						<div>
							<p>
								<span>{_("sent_thanks")}</span>
							</p>
						</div>
						<div>
							<p>
								<strong>{_("sent_success")}</strong>
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* <pre>stepName: {stepName}</pre> */}
			{/* <pre>activeOptionIndex: {activeOptionIndex}</pre> */}
			{/* <pre>Path: {Path.map((p) => `[${p}]`).join(".")}</pre> */}
			<pre>{JSON.stringify(Data, null, "  ")}</pre>
		</div>
	)
}

export default Wizard
